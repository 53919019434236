import React, { useContext, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { ThemeContext } from '~/context/ThemeContext';
import themes from '~/utils/themes';
import SEO from '~/components/Seo';
import HTMLContent from '~/components/HTMLContent';
import Footer from '~/components/Footer';
import styled from '@emotion/styled';
import { breakpoints, PageHeader, PageHeadline } from '~/utils/styles';
import { LegalPageContent } from '~/utils/types';

const PageContainer = styled.div`
    margin: auto;
    max-width: 600px;
    line-height: 1.25;
    padding: 0 1.5rem;

    li + li {
        margin-top: 1rem;
    }

    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + h5 {
        margin-top: 3rem;
    }

    a {
        text-transform: none;
    }
`;

const PolicyHeadlineHeadline = styled(PageHeadline)(() => `
    font-size: 1.25rem;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.02em;

    @media ${breakpoints.lg} {
        font-size: 2rem;
    }
`);

const LegalPage = ({ data: rawData, pageContext }: any) => {
    const { setTheme } = useContext(ThemeContext);
    const { id } = pageContext;
    const allLegalPages: LegalPageContent[] = Object.values(rawData.storeFront.legalContent);
    const data = allLegalPages.find((legalPage: LegalPageContent) => (
        legalPage?.id === id
    ));

    useEffect(() => {
        setTheme(themes.black);
    }, [setTheme]);

    if (!data) {
        navigate('/');

        return null;
    }

    return (
        <>
            <SEO title={data.title} />

            <PageHeader>
                <PolicyHeadlineHeadline>
                    {data.title}
                </PolicyHeadlineHeadline>
            </PageHeader>

            <PageContainer>
                <HTMLContent
                    html={data.body}
                />
            </PageContainer>

            <Footer />
        </>
    );
};

export const query = graphql`
    query {
        storeFront {
            legalContent: shop {
                privacyPolicy {
                    id
                    title
                    body
                    handle
                    url
                }
                termsOfService {
                    id
                    title
                    body
                    handle
                    url
                }
                shippingPolicy {
                    id
                    title
                    body
                    handle
                    url
                }
                refundPolicy {
                    id
                    title
                    body
                    handle
                    url
                }
            }
        }
    }
`;

export default LegalPage;
